import Vue from 'vue';
import Router from 'vue-router';
import Home from "@/components/Home";
import Career from "@/components/Career";
import Error from './components/Error';

Vue.use(Router);
const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: '',
	linkExactActiveClass: '',
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
		},
		{
			path: '/error',
			name: "error",
			component: Error,
		},
		{
			path: '/:career',
			name: 'career',
			component: Career,
		},
		{
			path: '*',
			name: "NotFound",
			component: Error,
		}
	],
	scrollBehavior(to) {
		if(to.name === 'career'){
			document.documentElement.style.scrollBehavior = 'auto';
			return { x: 0, y: 0 };
		}else{
			document.documentElement.style.scrollBehavior = 'smooth';
		}
	},
	});

/*router.onError(() => {
	this.push({path: `/${getDefaultLang()}/error`});
});

function setAutoScrollBehavior () {
	document.documentElement.style.scrollBehavior = 'auto';
}


function getDefaultLang(){
	let preferredLang = localStorage.getItem('preferredLang') || navigator.language || navigator.userLanguage || navigator.browserLanguage || 'en';
	preferredLang = preferredLang.toLowerCase();
	preferredLang = preferredLang.substr(0, 2);
	if(!/(en)|(zh)/.test(preferredLang)){
		console.log('navigator language is not supported');
		preferredLang = 'en';
	}
	return preferredLang;
}
function setLang(lang){
	let localLang = localStorage.getItem('preferredLang');
	if(!localLang || localStorage !== lang){
		localStorage.setItem('preferredLang', lang);
	}
	document.documentElement.lang = lang;
	setAutoScrollBehavior();
}*/


export default router;