export class Fullpage {
	constructor(element, customOptions){
		this.isTouchDevice = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
		this.isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0) || (navigator.maxTouchPoints));
		this.windowsHeight = getWindowHeight();
		this.windowWidth = getWindowWidth();
		this.minScreenHeight = 500;
		this.canScroll = true;
		this.scrollerTimer = null;
		
		this.scrollingDirection = 0; //0 means down, 1 means up
		
		this.isScrolling = false;
		this.scrollingTimer = null;
		
		this.container = $(element);
		this.pages=[];
		this.pagesNum = 0;
		
		this.currentPageIndex = 0;
		
		this.touchStartPointY = null;
		
		this.options = Object.assign({
			//navigation
			anchors:[],
			
			//scrolling
			css3: true,
			scrollingSpeed: 1000, // ms
			
			//events
			callback: null,
			log: null,
			
		}, customOptions);
		this.init();
		
	}
	
	init(){
		this.setContainerStyle()
		this.setPages();
		this.addHandler();
	}
	
	setContainerStyle(){
		this.options.css3 = support3d();
		if(this.options.css3){
			document.body.style.height = '100%';
			document.body.style.overflow = 'hidden';
			document.documentElement.style.height = '100%';
			document.documentElement.style.overflow = 'hidden';
		}else{
			document.body.style.height = 'auto';
			document.body.style.overflow = 'auto';
			document.documentElement.style.height = 'auto';
			document.documentElement.style.overflow = 'auto';
		}
	}
	
	setPages() {
		let pages = this.container.children;
		let winHeight = getWindowHeight();
		this.windowsHeight = winHeight < this.minScreenHeight ? this.minScreenHeight : winHeight;
		Array.prototype.forEach.call(pages, (page)=> {
			page.style.height = this.windowsHeight + 'px';
		})
		this.pagesNum = pages.length;
		this.setScroll(this.currentPageIndex * this.windowsHeight);
	}
	
	addHandler(){
		let wheel = Object.prototype.hasOwnProperty.call(window, 'onwheel') ? 'wheel' :'mousewheel';
		this.container.addEventListener(wheel, this.mouseWheelHandler.bind(this), false);
		this.container.addEventListener('touchstart', this.touchStartHandler.bind(this), false);
		this.container.addEventListener('touchmove', this.touchMoveHandler.bind(this), false);
		this.container.addEventListener('touchend', this.touchEndHandler.bind(this), false);
	}
	
	mouseWheelHandler(e){
		if(this.canScroll){
			this.canScroll = false;
			this.scrollerTimer = setTimeout(() => {
				this.canScroll = true;
			}, this.options.scrollingSpeed)
			e = window.event || e || e.originalEvent;
			let value = e.wheelDelta || -e.deltaY || -e.detail;
			let delta = Math.max(-1, Math.min(1, value));
			if (delta < 0) {
				this.nextPage();
			}else {
				this.prevPage();
			}
		}
		
	}
	
	touchStartHandler(e){
		this.touchStartPointY = e.touches[0].pageY;
		// this.options.log('touchstart')
	}
	
	touchMoveHandler(e){
		// this.options.log('touchmove' + new Date().getTime() )
		if(this.canScroll) {
			let y = e.touches[0].pageY;
			// this.options.log('touchmove Y:' + y )
			let diffY = y - this.touchStartPointY;
			if (Math.abs(diffY) > 60) {
				
				this.touchStartPointY = y;
				// alert(diffY)
				// this.options.log('can  scroll')
				this.canScroll = false;
				this.scrollerTimer = setTimeout(() => {
					this.canScroll = true;
					// this.options.log('set can  scroll true')
				}, this.options.scrollingSpeed)
				
				if (diffY > 0) {
					this.prevPage();
				} else {
					this.nextPage();
				}
			}
		}
	
	}
	
	touchEndHandler(){
		// this.options.log('touchend')
		this.touchStartPointY = null;
		
	}
	
	nextPage(){
		if(this.currentPageIndex < this.pagesNum - 1) {
			this.goToPageByIndex(this.currentPageIndex + 1);
		}
	}
	
	prevPage(){
		if(this.currentPageIndex > 0) {
			this.goToPageByIndex(this.currentPageIndex - 1);
		}
	}
	
	scrollPage(destPageIndex){
		let scrollStep = destPageIndex - this.currentPageIndex;
		let anchor = this.options.anchors[destPageIndex];
		let offsetY = (this.currentPageIndex + scrollStep)  * this.windowsHeight;
		this.scrollingDirection = scrollStep > 0 ? 0 : 1;
		this.setScroll(offsetY);
		
		this.isScrolling = true;
		this.scrollingTimer = setTimeout(() => {
			this.isScrolling = false;
		}, this.options.scrollingSpeed + 1000)
		
		//callback after scroll
		this.currentPageIndex += scrollStep;
		this.setUrlHash(anchor);
		this.callback();
	}
	
	setScroll(offset){
		let translate3d = '';
		if(this.options.css3){
			translate3d = 'translate3d(0px,' + -offset + 'px, 0px)';
			this.setTransforms(this.container, translate3d);
		}else{
			document.documentElement.scrollTop = Math.abs(offset);
		}
	}
	
	callback(){
		if(Object.prototype.toString.call(this.options.callback) === '[object Function]'){
			if(this.scrollingDirection === 0){
				setTimeout(() => {
					this.options.callback(this.currentPageIndex);
				}, this.options.scrollingSpeed - 300);
			}else{
				this.options.callback(this.currentPageIndex);
			}
		}
	}
	
	
	goToPageByIndex(index){
		if(index === this.currentPageIndex) return
		this.scrollPage(index);
	}
	
	goToPageByAnchor(anchor){
		//getting the anchor link in the URL and deleting the `#`
		anchor =  anchor || window.location.hash.replace('#', '');
		let index = this.options.anchors.findIndex(a => a === anchor);
		this.goToPageByIndex(index);
	}
	
	
	setTransforms(element, translate3d){
		this.css(element, {
			'-webkit-transform': translate3d,
			'-moz-transform': translate3d,
			'-ms-transform': translate3d,
			'transform': translate3d
		});
	}
	
	css( el, props ){
		let key;
		for ( key in props ) {
			if ( Object.prototype.hasOwnProperty.call(props, key) ) {
				if ( key !== null ) {
					el.style[key] = props[key];
				}
			}
		}
		return el;
	}
	
	
	/**
	 * Sets the URL hash.
	 */
	setUrlHash(url){
		window.location.hash = url;
	}
	
}

/**
 * Checks for translate3d support
 * @return boolean
 * http://stackoverflow.com/questions/5661671/detecting-transform-translate3d-support
 */
function support3d() {
	var el = document.createElement('p'),
		has3d,
		transforms = {
			'webkitTransform':'-webkit-transform',
			'OTransform':'-o-transform',
			'msTransform':'-ms-transform',
			'MozTransform':'-moz-transform',
			'transform':'transform'
		};
	
	// Add it to the body to get the computed style.
	document.body.insertBefore(el, null);
	
	for (var t in transforms) {
		if (el.style[t] !== undefined) {
			el.style[t] = 'translate3d(1px,1px,1px)';
			has3d = window.getComputedStyle(el).getPropertyValue(transforms[t]);
		}
	}
	
	document.body.removeChild(el);
	return (has3d !== undefined && has3d.length > 0 && has3d !== 'none');
}

/**
 * Simulates the animated scrollTop of jQuery. Used when css3:false or scrollBar:true or autoScrolling:false
 * http://stackoverflow.com/a/16136789/1081396
 */
/*function scrollTo(element, to, duration, callback) {
	var start = document.documentElement.scrollTop;
	var change = to - start;
	var currentTime = 0;
	var increment = 20;
	activeAnimation = true;
	
	var animateScroll = function(){
		if(activeAnimation){ //in order to stope it from other function whenever we want
			var val = to;
			
			currentTime += increment;
			val = Math.easeInOutCubic(currentTime, start, change, duration);
			
			setScrolling(element, val);
			
			if(currentTime < duration) {
				setTimeout(animateScroll, increment);
			}else if(typeof callback !== 'undefined'){
				callback();
			}
		}else if (currentTime < duration){
			callback();
		}
	};
	
	animateScroll();
}*/


function getWindowWidth(){
	return  'innerWidth' in window ? window.innerWidth : document.documentElement.offsetWidth;
}

function getWindowHeight(){
	return  'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
}

function $(selector, context) {
	context = context || document;
	return context.querySelector(selector);
}


