<template>
  <main class="career">
    <section class="career-top">
      <div class="content">
        <h2 class="career-headline">{{ career.position }}</h2>
        <p class="career-sub-head">Location - {{ career.place }}</p>
      </div>
    </section>
    <div class="content">
      <section class="left-con">
        <dl class="txt-con" v-if="career.descri">
          <dt class="heading">Description</dt>
          <dd class="text-item">{{career.descri}}</dd>
        </dl>
        <dl class="txt-con" v-if="career.descri">
          <dt class="heading">Requirements:</dt>
          <dd class="text-item text-dot" v-for="(rd, i) in career.required.split('\n')" :key="`required-${i}`">{{ rd }}</dd>
        </dl>
        <dl class="txt-con" v-if="career.descri">
          <dt class="heading">Plus:</dt>
          <dd class="text-item text-dot" v-for="(p, i) in career.plus.split('\n')" :key="`plus-${i}`">{{ p }}</dd>
        </dl>
        <dl class="txt-con" v-if="career.descri">
          <dt class="heading">Quest Items:</dt>
          <dd class="text-item text-dot" v-for="(m, i) in career.materials.split('\n')" :key="`m-${i}`">{{ m }}</dd>
        </dl>
      </section>
      <div class="right-con">
        <h2 class="heading">Careers</h2>
        <ul>
          <li v-for="(c, i) in careers" :key="`career-link-${i}`" class="highlight-link" >
            <router-link :to="`${c.alias}`">{{c.position}}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="career-contact">
      <section class="content">
        <p>Contact</p>
        <p class="email">HR@SOURCETECH.XYZ</p>
      </section>
    </div>
    <footer>
      <p class="content">© {{new Date().getFullYear()}} Source Technology. All Rights Reserved</p>
    </footer>

  </main>
</template>

<script>
import { scrollTop } from '@/utils/utils';
export default {
  name: "Career",
  data(){
    return {
      isNavFixed: false,
      scrollBinder: null,
      careerAlias: this.$route.params.career,
      career: {
        id: null,
        position: '',
        alias: '',
        place: '',
        time: '',
        descri: '',
        responsibility: '',
        required: '',
        plus: '',
        materials: '',
      },
    }
  },
  props: {
    careers: Array,
  },
  beforeMount() {
    document.documentElement.style.scrollBehavior = 'auto';
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollBinder, false);
  },
  watch: {
    $route(to, from) {
      // 对路由变化作出响应...
      // console.log('watch router')
      // console.log(to, from);
      if(to.params.career !== from.params.career){
        this.careerAlias = to.params.career;
        this.loadData();
      }
    },
  },
  mounted() {
    this.initBodyClass();
    this.loadData();
    this.scrollBinder = this.scrolling.bind(this);
    this.$emit('update-nav', 'white');
    document.addEventListener('scroll', this.scrollBinder, false);
  },

  methods: {
    initBodyClass(){
      document.body.style.height = 'auto';
      document.body.style.overflow = 'auto';
      document.documentElement.style.height = 'auto';
      document.documentElement.style.overflow = 'auto';
    },
    loadData(){
      this.career = this.careers.find(item => item.alias === this.careerAlias);
      // console.log(this.career);
      if(!this.career){
        this.$router.push('/error');
      }
    },
    scrolling(){
      let _scrollTop = scrollTop();
      if(_scrollTop > 0 && !this.isNavFixed){
        this.isNavFixed = true;
        this.$emit('update-nav', 'fixed');
      }else if(_scrollTop === 0 && this.isNavFixed){
        this.isNavFixed = false;
        this.$emit('update-nav', 'white');
      }
    },
  }
}
</script>
